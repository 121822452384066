import PropTypes from "prop-types";
import classNames from "classnames";
import * as React from "react";
import {Link as GatsbyLink} from "gatsby";
import LinkArrow from "../../assets/svg/link/arrow.svg";
import LinkArrowLine from "../../assets/svg/link/line.svg";
import * as linkStyles from "./link.module.css";

const LinkContent = ({children, withArrow}) => {
    return (
        <span>
            {children}{" "}
            {withArrow && (
                <span className={linkStyles.link__arrow}>
                    <LinkArrowLine />
                    <LinkArrow className={linkStyles.arrow} />
                </span>
            )}
        </span>
    );
};

const Link = (props) => {
    const {children, to, activeClassName, partiallyActive, className, withArrow, small, getProps, ...other} = props;

    const internal = /^\/(?!\/)/.test(to);

    const linkClasses = classNames(
        linkStyles.link,
        {
            [linkStyles.link_withArrow]: withArrow,
            [linkStyles.link_small]: small,
        },
        className,
    );

    return internal ? (
        <GatsbyLink
            to={to}
            className={linkClasses}
            activeClassName={activeClassName}
            partiallyActive={partiallyActive}
            getProps={getProps}
            {...other}
        >
            <LinkContent withArrow={withArrow}>{children}</LinkContent>
        </GatsbyLink>
    ) : (
        <a className={linkClasses} href={to} {...other} target="_blank" rel="noreferrer">
            <LinkContent withArrow={withArrow}>{children}</LinkContent>
        </a>
    );
};

Link.defaultProps = {
    activeClassName: "",
    className: "",
    children: [],
    partiallyActive: false,
    replace: undefined,
    state: {},
    withArrow: true,
    small: false,
};

Link.propTypes = {
    to: PropTypes.string.isRequired,
    activeClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    activeStyle: PropTypes.shape({}),
    partiallyActive: PropTypes.bool,
    replace: PropTypes.bool,
    state: PropTypes.shape({}),
    withArrow: PropTypes.bool,
    small: PropTypes.bool,
    getProps: PropTypes.func,
};

export default Link;
