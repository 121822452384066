import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import * as slideUpStyles from "./slide-up.module.css";

const SlideUp = () => {
    const [isVisible, setVisible] = useState(false);

    const scrollHandler = useMemo(() => {
        let haveBeenCalled = false;

        return () => {
            if (haveBeenCalled) {
                return;
            }

            setVisible(window.scrollY > 1000);

            haveBeenCalled = true;
            setTimeout(() => {
                haveBeenCalled = false;
            }, 300);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);

        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [scrollHandler]);

    const scrollTop = useCallback(() => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }, []);

    return (
        <button
            onClick={scrollTop}
            type="button"
            className={`${slideUpStyles.slideUp} ${isVisible ? slideUpStyles.slideUp_show : ""}`}
        >
            <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="64" height="64" rx="32" fill="white" stroke="#F3336C" />
                <path d="M32.4985 44.2L32.4985 20.8" stroke="#F3336C" strokeWidth="3" strokeLinecap="round" />
                <path d="M24.7 28.6L32.4985 20.8L40.3 28.6" stroke="#F3336C" strokeWidth="3" strokeLinecap="round" />
            </svg>
        </button>
    );
};

export default SlideUp;
