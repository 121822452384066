// extracted by mini-css-extract-plugin
export var footer__nav = "footer-module--footer__nav--LLjN3";
export var footer__navTitle = "footer-module--footer__nav-title--rpg92";
export var footer__navListItem = "footer-module--footer__nav-list-item--Wr16g";
export var footer__navLink = "footer-module--footer__nav-link---uzGr";
export var footer__info = "footer-module--footer__info--a91CT";
export var footer__copyright = "footer-module--footer__copyright--7PmLh";
export var footer__socialList = "footer-module--footer__social-list--IsL-g";
export var footer__socialListItem = "footer-module--footer__social-list-item--y9Xgp";
export var footer__socialNetwork = "footer-module--footer__social-network--50voR";
export var footer = "footer-module--footer--yUfeg";