import classNames from "classnames/bind";
import * as React from "react";
import Header from "./Header";
import Footer from "./Footer";
import SlideUp from "../Common/slideUp";
import * as layoutStyles from "./layout.module.css";

const cx = classNames.bind(layoutStyles);

const Layout = ({
    children,
    className = "",
    safeOverflow = false,
    needFiltersButton = false,
    onFiltersClick = () => {},
}) => {
    return (
        <div className={cx({layout: true, layout_safeOverflow: safeOverflow}, className)}>
            <Header needFiltersButton={needFiltersButton} onFiltersClick={onFiltersClick} />
            <main>{children}</main>
            <Footer />
            <SlideUp />
        </div>
    );
};

export default Layout;
