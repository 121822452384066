import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {globalHistory} from "@reach/router";
import {useMediaQuery} from "@react-hook/media-query";
import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {useEffect, useState} from "react";
import useIsMounted from "../../../hooks/useIsMounted";
import Button from "../../UI/Button";
import Link from "../../UI/Link";
import Logo from "../../../assets/svg/logo.svg";
import Filters from "../../../assets/svg/filters.svg";
import Burger from "../../../assets/svg/burger.svg";
import Banner from "../Banner";
import NavBar from "./NavBar";
import * as headerStyles from "./header.module.css";

const Header = ({needFiltersButton = false, onFiltersClick = () => {}}) => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            strapiSettings {
                headerMenu
                headerButtonURL
                link_to_lk
                banner {
                    buttonText
                    newTab
                    text
                    url
                    id
                }
            }
            images: allFile(filter: {parent: {id: {eq: "Settings_1"}}}) {
                edges {
                    node {
                        name
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                        svg {
                            content
                        }
                        internal {
                            mediaType
                        }
                    }
                }
            }
        }
    `);
    const {
        strapiSettings: {headerMenu: menu, headerButtonURL: buttonURL, link_to_lk: linkToLK, banner},
        images,
    } = data;

    const idToImage = images.edges.reduce((map, {node}) => {
        return {...map, [node.name]: {mime: node.internal.mediaType, localFile: {...node}}};
    }, {});

    const [realMenu] = useState(
        menu.map((item) => {
            const newItem = {
                ...item,
            };

            if (item.section) {
                newItem.section = item.section.map((section) => {
                    const newSection = {
                        ...section,
                    };

                    newSection.links = section.links.map((link) => {
                        if (!link.icon) {
                            return {...link};
                        }

                        return {
                            ...link,
                            icon: {...idToImage[link.icon?.hash]},
                        };
                    });

                    return newSection;
                }, []);
            }

            return newItem;
        }, []),
    );

    const isMounted = useIsMounted();
    const isDesktop = useMediaQuery("(min-width: 1311px)");

    const [shouldRenderMobileMenu, setShouldRenderMobileMenu] = useState(false);
    useEffect(() => {
        setShouldRenderMobileMenu(!isMounted() || !isDesktop);
    }, [isMounted, isDesktop]);

    const [isMenuOpened, setMenuOpenedState] = useState(false);
    useEffect(() => {
        if (!shouldRenderMobileMenu) {
            return;
        }

        setMenuOpenedState(false);
    }, [shouldRenderMobileMenu]);

    const toggleMobileMenu = () => {
        setMenuOpenedState(!isMenuOpened);
    };

    globalHistory.listen(() => setMenuOpenedState(false));

    const [isSticky, setIsSticky] = useState(false);
    useScrollPosition(
        ({currPos: {y: scrollPosY}}) => {
            const absoluteScrollPosY = Math.abs(scrollPosY);

            if (absoluteScrollPosY > 0) {
                setIsSticky(isSticky || absoluteScrollPosY > 200);
            } else {
                setIsSticky(false);
            }
        },
        [isSticky],
        undefined,
        false,
        300,
    );

    return (
        <>
            {banner && (
                <Banner
                    text={banner.text}
                    buttonText={banner.buttonText}
                    newTab={banner.newTab}
                    url={banner.url}
                    hide={isMenuOpened}
                />
            )}
            <header className={headerStyles.header}>
                <div
                    className={`${headerStyles.header__wrapper}${
                        isMenuOpened ? ` ${headerStyles.header__wrapper_menuOpened}` : ""
                    }${isSticky ? ` ${headerStyles.header__wrapper_sticky}` : ""}`}
                >
                    <div className={headerStyles.header__container}>
                        <div className={headerStyles.header__logo}>
                            <Link to="/" withArrow={false}>
                                <Logo />
                            </Link>
                        </div>
                        <div className={headerStyles.header__nav}>
                            {!shouldRenderMobileMenu && (
                                <NavBar isMobileMenuOpened={false} menu={realMenu} linkToLK={linkToLK} />
                            )}{" "}
                            {buttonURL && (
                                <Button
                                    className={headerStyles.header__button}
                                    variant="primary"
                                    isLink
                                    href={buttonURL}
                                >
                                    Попробовать бесплатно
                                </Button>
                            )}
                            {needFiltersButton && (
                                <button
                                    type="button"
                                    onClick={onFiltersClick}
                                    className={`${headerStyles.filtersButton}`}
                                >
                                    <Filters />
                                </button>
                            )}
                            <button
                                type="button"
                                onClick={toggleMobileMenu}
                                className={`${headerStyles.burger}${
                                    isMenuOpened ? ` ${headerStyles.burger_active}` : ""
                                }`}
                                aria-label={isMenuOpened ? "Открыть меню" : "Закрыть меню"}
                            >
                                <Burger />
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    {shouldRenderMobileMenu && (
                        <NavBar isMobileMenuOpened={isMenuOpened} menu={realMenu} linkToLK={linkToLK} />
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;
