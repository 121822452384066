import cx from "classnames";
import * as React from "react";
import PropTypes from "prop-types";
import * as styles from "./container.module.css";

const Container = ({children, className}) => {
    return <div className={cx(styles.container, className)}>{children}</div>;
};

Container.defaultProps = {
    children: [],
    className: "",
};

Container.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Container;
