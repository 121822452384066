import * as React from "react";
import {graphql, useStaticQuery} from "gatsby";
import Media from "../Common/Media";
import Link from "../UI/Link";
import Container from "./Container";
import * as footerStyles from "./footer.module.css";

const Footer = () => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            strapiSettings {
                footerMenu
                social_networks {
                    link
                    title
                    icon {
                        mime
                        width
                        height
                        localFile {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        strapiSettings: {footerMenu: menu, social_networks: socialNetworks},
    } = data;

    return (
        <footer>
            <Container>
                <nav className={footerStyles.footer__nav}>
                    {menu.map((section, idx) => {
                        return (
                            <div key={idx}>
                                <h4 className={footerStyles.footer__navTitle}>{section.title}</h4>
                                <ul>
                                    {section.links.map((link, linkIdx) => {
                                        return (
                                            <li className={footerStyles.footer__navListItem} key={linkIdx}>
                                                <Link
                                                    to={
                                                        /^\/(?!\/)/.test(link.url)
                                                            ? `${section.url || ""}${link.url}`
                                                            : link.url
                                                    }
                                                    withArrow={false}
                                                    className={footerStyles.footer__navLink}
                                                >
                                                    {link.title}
                                                </Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </nav>
                <div className={footerStyles.footer__info}>
                    <p className={footerStyles.footer__copyright}>
                        <span>© {new Date().getFullYear()} ГК Naumen, ООО «Смартнат». </span>
                        <span>
                            Разработка сайта компания{" "}
                            <Link to="https://koptelnya.ru" withArrow={false}>
                                Коптельня
                            </Link>
                        </span>
                    </p>
                    <ul className={footerStyles.footer__socialList}>
                        {socialNetworks.map((network, idx) => {
                            return (
                                <li className={footerStyles.footer__socialListItem} key={idx}>
                                    <Link
                                        to={network.link}
                                        withArrow={false}
                                        className={footerStyles.footer__socialNetwork}
                                        title={network.title}
                                    >
                                        <Media media={{content: network.icon, youtubeVideoURL: ""}} />
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
