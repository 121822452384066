import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import React, {useCallback, useEffect, useRef, useState} from "react";
import useWatchOutside from "../../../hooks/useWatchOutside";
import Media from "../../Common/Media";
import Link from "../../UI/Link";
import * as navStyles from "./navbar.module.css";
import Arrow from "../../../assets/svg/arrow.svg";
import Button from "../../UI/Button";

const isBrowser = typeof window !== "undefined";

const NavBar = ({isMobileMenuOpened, menu, linkToLK}) => {
    const menuRef = useRef(null);

    const [openedDropdownIndex, setOpenedDropdownIndex] = useState(-1);

    useEffect(() => {
        if (isMobileMenuOpened && menuRef.current) {
            disableBodyScroll(menuRef.current, {reserveScrollBarGap: true});

            return;
        }

        if (menuRef.current) {
            enableBodyScroll(menuRef.current);
        }

        setOpenedDropdownIndex(-1);
        clearAllBodyScrollLocks();
    }, [isMobileMenuOpened]);

    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const toggleDropdown = useCallback((index, isFocusHandler = false) => {
        setOpenedDropdownIndex((currentIndex) => {
            if (!isFocusHandler && currentIndex === index) {
                return -1;
            }

            return index;
        });
    }, []);

    useWatchOutside(
        menuRef,
        useCallback(() => toggleDropdown(-1), [toggleDropdown]),
    );

    const getNavSection = (entry, idx, isLast = false) => {
        const getLink = (link, linkIdx) => {
            return (
                <li key={linkIdx}>
                    <Link
                        to={/^\/(?!\/)/.test(link.url) ? `${entry.url || ""}${link.url}` : link.url}
                        withArrow={false}
                        className={navStyles.nav__submenuLink}
                        activeClassName={navStyles.nav__submenuLink_active}
                        partiallyActive
                    >
                        <div className={navStyles.nav__submenuLinkContent}>
                            {link.icon && (
                                <Media
                                    media={{content: link.icon, youtubeVideoURL: ""}}
                                    className={navStyles.nav__submenuLinkIcon}
                                />
                            )}
                            <div>
                                <p className={navStyles.nav__submenuLinkTitle}>{link.title}</p>
                                {link.description && (
                                    <p className={navStyles.nav__submenuLinkDesc}>{link.description}</p>
                                )}
                            </div>
                        </div>
                    </Link>
                </li>
            );
        };

        return (
            <div key={idx}>
                {entry.title && <p className={navStyles.nav__submenuTitle}>{entry.title}</p>}
                <ul className={navStyles.nav__submenuList}>
                    {entry.links.map(getLink)}
                    {isLast && !!linkToLK && (
                        <Button isLink href={linkToLK} variant="primary" className={navStyles.nav__button}>
                            Войти в личный кабинет
                        </Button>
                    )}{" "}
                </ul>
            </div>
        );
    };

    const getNavItem = (entry, idx, arr) => {
        if (!entry.section) {
            return (
                <li className={navStyles.nav__item} key={idx}>
                    <Link
                        className={navStyles.nav__link}
                        activeClassName={navStyles.nav__link_active}
                        partiallyActive
                        to={entry.url}
                        withArrow={false}
                    >
                        {entry.title}
                    </Link>
                </li>
            );
        }

        const isActive = entry.section.reduce((acc, {url, links}) => {
            if (!isBrowser) {
                return acc;
            }

            const haveActiveLink = links.reduce((containActiveLink, {url: linkUrl}) => {
                const urlToTest = /^\/(?!\/)/.test(linkUrl) ? `${url || ""}${linkUrl}` : linkUrl;

                if (containActiveLink) {
                    return containActiveLink;
                }

                if (!isBrowser) {
                    return false;
                }

                const doesURLContainsURLToTest = window.location.href.indexOf(urlToTest) > 0;
                if (!doesURLContainsURLToTest) {
                    return false;
                }

                const urlParts = window.location.href.split(urlToTest);
                return urlParts[1].length === 0;
            }, false);

            return acc || haveActiveLink;
        }, false);

        return (
            <li
                className={`${navStyles.nav__item}${
                    openedDropdownIndex === idx ? ` ${navStyles.nav__item_opened}` : ""
                }`}
                key={idx}
            >
                <button
                    className={`${navStyles.nav__link}${isActive ? ` ${navStyles.nav__link_active}` : ""}`}
                    type="button"
                    onClick={() => toggleDropdown(idx)}
                >
                    {entry.title} <Arrow />
                </button>
                <div
                    className={`${navStyles.nav__submenu}${
                        idx === arr.length - 1 ? ` ${navStyles.nav__submenu_last}` : ""
                    }`}
                    onFocusCapture={() => toggleDropdown(idx, true)}
                >
                    {entry.section.map((submenuItem, submenuIdx) => {
                        const isLast = arr.length - 1 === idx;
                        return getNavSection(submenuItem, submenuIdx, isLast);
                    })}
                </div>
            </li>
        );
    };

    return (
        <nav>
            <ul className={`${navStyles.nav} ${isMobileMenuOpened ? navStyles.nav_opened : ""}`} ref={menuRef}>
                {menu.map(getNavItem)}
            </ul>
        </nav>
    );
};

export default NavBar;
