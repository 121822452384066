// extracted by mini-css-extract-plugin
export var nav = "navbar-module--nav--6zmav";
export var nav__item = "navbar-module--nav__item--AIMrF";
export var nav__link = "navbar-module--nav__link--IUybY";
export var nav__item_opened = "navbar-module--nav__item_opened--h3XUs";
export var nav__submenu = "navbar-module--nav__submenu--BoL83";
export var nav__button = "navbar-module--nav__button--4a+2j";
export var nav__link_active = "navbar-module--nav__link_active--kVoao";
export var nav__submenu_last = "navbar-module--nav__submenu_last--vf8F4";
export var nav__submenuTitle = "navbar-module--nav__submenu-title--i5Zh6";
export var nav__submenuList = "navbar-module--nav__submenu-list--9QSp2";
export var nav__submenuLink = "navbar-module--nav__submenu-link--uh6GC";
export var nav__submenuLink_active = "navbar-module--nav__submenu-link_active--iTSaU";
export var nav__submenuLinkTitle = "navbar-module--nav__submenu-link-title--MEwIw";
export var nav__submenuLinkContent = "navbar-module--nav__submenu-link-content--jhLn6";
export var nav__submenuLinkIcon = "navbar-module--nav__submenu-link-icon--5Pz80";
export var nav__submenuLinkDesc = "navbar-module--nav__submenu-link-desc--Ra2sA";
export var nav_opened = "navbar-module--nav_opened--JoFPI";