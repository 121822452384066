import {useEffect, useState} from "react";
import * as React from "react";
import cx from "classnames";
import Button from "../../UI/Button";
import * as styles from "./styles.module.css";

const Banner = ({buttonText, newTab, text, url, hide}) => {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsOpened(true);
        }, 3000);
    }, []);

    if (!text) {
        return null;
    }

    return (
        <div
            className={cx(styles.banner__wrapper, {
                [styles.banner__wrapper_opened]: isOpened,
                [styles.banner__wrapper_hidden]: hide,
            })}
        >
            <div className={cx(styles.banner, {[styles.banner_opened]: isOpened})}>
                <p className={styles.banner__content}>{text}</p>
                {buttonText && url && (
                    <Button
                        type="button"
                        variant="primary"
                        isLink
                        href={url}
                        external={newTab}
                        className={styles.banner__button}
                    >
                        {buttonText}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Banner;
