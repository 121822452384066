import classNames from "classnames/bind";
import PropTypes from "prop-types";
import * as React from "react";
import * as buttonStyles from "./button.module.css";

const cx = classNames.bind(buttonStyles);

const ButtonContent = ({icon, iconPosition, children}) => {
    const iconClasses = cx({
        button__icon: !!icon,
        button__icon_right: !!icon && iconPosition === "right",
    });

    return (
        <>
            {iconPosition === "left" && icon && <span className={iconClasses}>{icon}</span>} {children}{" "}
            {iconPosition === "right" && icon && <span className={iconClasses}>{icon}</span>}
        </>
    );
};

const Button = ({
    className,
    children,
    href,
    isLink,
    type,
    variant,
    disabled,
    icon,
    iconPosition,
    isActive,
    onClick,
    loading,
    external,
    ...other
}) => {
    const buttonsClasses = cx(
        {
            button: true,
            button_active: isActive,
            button_gradient: variant === "gradient",
            button_primary: variant === "primary",
            button_text: variant === "text",
            button_loading: loading && !disabled,
        },
        className,
    );

    const internal = external !== undefined ? !external : isLink && /^\/(?!\/)/.test(href);
    const additionalProps = internal ? {} : {target: "_blank", rel: "noreferrer"};

    return isLink ? (
        <a href={href} className={buttonsClasses} onClick={onClick} {...other} {...additionalProps}>
            <ButtonContent iconPosition={iconPosition} icon={icon}>
                {children}
            </ButtonContent>
        </a>
    ) : (
        <button
            className={buttonsClasses}
            disabled={disabled}
            type={type === "button" ? "button" : "submit"}
            onClick={onClick}
            {...other}
        >
            <ButtonContent iconPosition={iconPosition} icon={icon}>
                {children}
            </ButtonContent>
        </button>
    );
};

Button.defaultProps = {
    type: "button",
    variant: "default",
    className: "",
    href: "",
    isLink: false,
    iconPosition: "left",
    children: null,
    disabled: false,
    icon: null,
    isActive: false,
    loading: false,
    external: undefined,
    onClick: () => {},
};

Button.propTypes = {
    type: PropTypes.oneOf(["button", "submit"]),
    variant: PropTypes.oneOf(["default", "primary", "text", "gradient"]),
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string,
    isLink: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    external: PropTypes.bool,
};

export default Button;
